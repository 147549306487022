<template>
  <router-view v-if="loadApp" />
</template>
<script>
export default {
  data: () => ({
    loadApp: false,
  }),
  created() {
    let result = this.$store.dispatch("getUser");

    result.catch(async () => {
      // error
      // console.warn("No se logro obtener usuario")
      await this.$store.dispatch("auth/logout");
      if(this.$route.name !== "login") {
        this.$router.push({ name: "login" });
      }
    });

    result.finally(() => {
      // console.info("Finally load");
      this.loadApp = true;
    })
  }
}
</script>
<style lang="scss">
@import "./style.scss";
</style>
